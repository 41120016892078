.content {
  margin: 5%;
}

.q1 {
  display: block;
}

.img1 {
  width: 300px;
  display: block;
}



.link {
  margin-top: 10px;
  border-radius: 5px;
  background-color: #F0F0F0;
  padding: 4px 8px;
  border: 1px solid #000;
  color: black;
  text-decoration: none;
  font-size:12px;
  font-family: Arial;
}